@mixin heading-40 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
}

@mixin heading-34 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 35px;
  line-height: 135%;
}

@mixin heading-30 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
}

@mixin heading-30-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 125%;
}

@mixin heading-28 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
}

@mixin heading-20 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}

@mixin heading-20-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
}

@mixin heading-20-medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}

@mixin heading-18 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
}

@mixin heading-16 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: auto;
}
@mixin heading-15 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: auto;
}

@mixin body-16 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}

@mixin body-14 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
}
