@import '/styles/colors';
@import '/styles/mixins';

.markdown {
  p {
    margin: 8px 0;
  }

  h1 {
    font-size: 58px;

    @media screen and (max-width: 768px) {
      font-size: 48px;
    }

    @media screen and (max-width: 480px) {
      font-size: 38px;
    }
  }
  h2 {
    font-size: 41px;

    @media screen and (max-width: 768px) {
      font-size: 35px;
    }

    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
  }
  h3 {
    font-size: 30px;

    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
  h4 {
    @include heading-18;
  }
  h5 {
    @include heading-16;
  }
  h6 {
    @include heading-15;
  }

  li input {
    margin-right: 8px;
  }

  ol,
  ul {
    margin-left: 48px;
  }

  a {
    color: $blue-01;
  }
}
