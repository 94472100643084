$blue-01: #1f81d1;
$blue-02: #3d9def;
$blue-03: #9ecef7;
$blue-04: #e2f0fd;
$blue-05: #0D71F0;
$blue-07: #0950A9;

$green-01: #3ce79e;
$green-02: #9df3ce;
$green-03: #d8faec;

$gray-01: #0f2233;
$gray-02: #293e50;
$gray-03: #445a6d;
$gray-04: #617588;

$white-05: #ffffff;

$background: #F4F4F7;

$orange-05: #FA8500;
