@import '/styles/colors';
@import '/styles/mixins';


.title {
  @include body-16;
  font-size: 32px;
  line-height: 130%;
  font-weight: 400;
  color: $gray-01;
}

.subtitle {
  @include body-16;
  padding-top: 1.5rem;
  color:$blue-02;
  font-weight: 700;
}

.description{
  @include body-16;
  padding-top: 1rem;
  font-weight: 400;
  word-break: break-word;
  color: $gray-04;
}

.cardIconsImg{
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  div{
    padding: 0;
  }
}

.icons{
  align-self: center !important;
  padding: 0 5rem
}

@media screen and (max-width: 1200px) {
  .card{
    padding-top: 0
  }
  .icons {
    padding: 0
  }
}
