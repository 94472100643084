@import '/styles/colors';
@import '/styles/mixins';

.iconList {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  margin: 0.5rem 0 0 0;
}

.iconListColunm {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  margin: 0.5rem 0 0 0;
}

.iconFrame {
  max-width: 400px;
  width: fit-content;
}

.iconList>*+* {
  margin: 0 0 0 1rem;
}
.title {
  @include body-16;
  color: $gray-01;
  width: 100%;
  font-weight: bold;
}

@media screen and (max-width: 1270px) {
  .title {
    @include body-16;
    font-weight: bold;
  }

  .iconFrame {
    align-self: center;
  }
}

