@import '/styles/mixins';
@import '/styles/colors';

.title {
  @include heading-16;
  color: $blue-02;
}

.link {
  @include heading-18;
  line-height: 160%;
  letter-spacing: 0.02em;
}
.link a {
  align-items: center;
  display: flex;
}
.link img {
  margin-left: 10px;
}
