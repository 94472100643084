@import '/styles/colors';

.headerGroup {
  display: flex;
  flex-direction: initial;
  justify-content: space-between !important;
}

.closeText {
  margin-left: 0.5rem;
  cursor: pointer;
}

.linkInfo {
  color: $blue-02;
  text-decoration: underline;
}

.closeButton {
  border-radius: 30px !important;
  background-color: rgba(13, 111, 240, 0.192);
  color: $blue-05
}

.contentBody {
  padding-top: 8rem;
}
